import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {microsoftAuthApiRef} from '@backstage/core-plugin-api';

// Plugins
import {HomepageCompositionRoot} from '@backstage/plugin-home';
import {HomePage} from './components/home/HomePage';
import * as plugins from './plugins';
import {ExplorePage} from '@backstage/plugin-explore';
import {OpsgeniePage} from '@k-phoen/backstage-plugin-opsgenie';
import {DevToolsPage} from '@backstage/plugin-devtools';
import {CatalogUnprocessedEntitiesPage} from '@backstage/plugin-catalog-unprocessed-entities';
import {CustomDevToolsPage} from "./components/devtools/CustomDevToolsPage";
import {ShouldIDeployPage} from "backstage-plugin-should-i-deploy";

const app = createApp({
    components: {
        SignInPage: props => (
            <SignInPage
                {...props}
                auto
                provider={{
                    id: 'microsoft-auth-provider',
                    title: 'Microsoft',
                    message: 'Sign in using Microsoft',
                    apiRef: microsoftAuthApiRef,
                }}
            />
        ),
    },
    apis,
    plugins: Object.values(plugins),
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            /* Disabling these things, so we only maintain components and apis through the Git*/
            /* createComponent: scaffolderPlugin.routes.root, */
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            /* registerApi: catalogImportPlugin.routes.importPage, */
        });
        bind(scaffolderPlugin.externalRoutes, {
            /* registerComponent: catalogImportPlugin.routes.importPage,
            *  viewTechDoc: techdocsPlugin.routes.docRoot,
            */
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot/>}>
            <HomePage/>
        </Route>
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage/>}>
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage/>}>
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        {/* NOTE (GS): We don't plan to use this
        <Route path="/create" element={<ScaffolderPage />} />
        */}
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800}/>}/>
        {/* NOTE (GS): We don't plan to use this
        <Route
          path="/catalog-import"
          element={
            <RequirePermission permission={catalogEntityCreatePermission}>
              <CatalogImportPage />
            </RequirePermission>
          }
        />*/}
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/should-i-deploy" element={<ShouldIDeployPage />} />
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/explore" element={<ExplorePage/>}/>
        {/* this integration has a huge flaw. On the incidents page it will fetch all requests from OpsGenie.
        The Daimler Organization on OpsGenie has > 6500, i.e. this page does 6500/50 requests ...  */}
        <Route path="/opsgenie" element={<OpsgeniePage/>}/>
        <Route path="/devtools" element={<DevToolsPage/>}>
            {CustomDevToolsPage}
        </Route>
        <Route path="/catalog-unprocessed-entities" element={<CatalogUnprocessedEntitiesPage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
