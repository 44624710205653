import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {
    ClockConfig,
    HeaderWorldClock,
    HomePageRandomJoke,
    HomePageStarredEntities,
    WelcomeTitle
} from '@backstage/plugin-home';
import {Content, Header, Page} from '@backstage/core-components';
import {HomePageSearchBar} from '@backstage/plugin-search';
import {SearchContextProvider} from '@backstage/plugin-search-react';
import {ShouldIDeployCard} from "backstage-plugin-should-i-deploy";

const useStyles = makeStyles(theme => ({
    searchBarRoot: {
        padding: '8px 8px',
        maxWidth: '55vw',
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: '50px',
        margin: 'auto',
    },
    searchBarOutline: {
        borderStyle: 'none',
    },
}));

export const HomePage = () => {
    const classes = useStyles();

    const clockConfigs: ClockConfig[] = [
        {
            label: 'CA',
            timeZone: 'America/Los_Angeles',
        },
        {
            label: 'UTC',
            timeZone: 'UTC',
        },
        {
            label: 'DE',
            timeZone: 'Europe/Berlin',
        },
        {
            label: 'IN',
            timeZone: 'Asia/Kolkata',
        },
        {
            label: 'CN',
            timeZone: 'Asia/Shanghai',
        },
        {
            label: 'KOR',
            timeZone: 'Asia/Seoul',
        },
    ];
    const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    return (
        <>
            <Header title={<WelcomeTitle/>} pageTitleOverride="Home">
                <HeaderWorldClock
                    clockConfigs={clockConfigs}
                    customTimeFormat={timeFormat}
                />
            </Header>
            <SearchContextProvider>
                <Page themeId="home">
                    <Content>
                        <Grid container justifyContent="center" spacing={3}>
                            <Grid container item xs={12} alignItems="center" direction="row">
                                <HomePageSearchBar
                                    placeholder="Search"
                                    InputProps={{
                                        classes: {
                                            root: classes.searchBarRoot,
                                            notchedOutline: classes.searchBarOutline,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <HomePageStarredEntities/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <HomePageRandomJoke/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ShouldIDeployCard title='Should I deploy today?' timeZone="Europe/Berlin"/>
                            </Grid>
                            {/* TODO(GS): Commented out for now since it HTTPS is missing and images are not loading
                            <Grid item md={4} xs={6} style={{height: "500px"}}>
                                <XkcdComicCard />
                            </Grid>*/}
                        </Grid>
                    </Content>
                </Page>
            </SearchContextProvider>
        </>
    );
};